.OverlayOpen {
  animation: openOverlay 0.5s ease-out forwards;
}

.OverlayClosed {
  animation: closeOverlay 0.35s ease-out forwards;
}

.ModalOpen {
  animation: openModal 0.5s ease-out forwards;
}

.ModalClosed {
  animation: closeModal 0.35s ease-out forwards;
}

@keyframes openOverlay {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes closeOverlay {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes openModal {
  0% {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }

  100% {
    opacity: 1;
    transform: translate3d(0%, 0, 0);
  }
}

@keyframes closeModal {
  0% {
    opacity: 1;
    transform: translate3d(0%, 0, 0);
  }

  100% {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }
}

.overlay {
  background: rgba(15, 16, 17, 0.4);
  backdrop-filter: blur(1px);
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 6300;
}

.popup {
  background-color: #0f1011;
  color: white;
  height: 100%;
  position: fixed;
  right: 0;
  top: 0;
  transform: translate3d(0, 0, 0);
  width: 50%;
  z-index: 6400;
}

.close {
  aspect-ratio: 1 / 1;
  position: fixed;
  right: 0.833333333333333vw;
  top: 0.833333333333333vw;
  width: 3.125vw;
  z-index: 6500;
}
.close > svg {
  width: 100%;
}
.close > svg > circle {
  fill: white;
  transition: fill 0.25s ease;
}

.popupLoading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.popupLoading > svg {
  aspect-ratio: 1 / 1;
  width: 10.416666666666667vw;
}

.popupContainer {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  height: 100%;
  overflow: auto;
  position: relative;
}

.visual {
  aspect-ratio: 16 / 9;
  flex: 0 0 auto;
  overflow: hidden;
  position: relative;
  width: 100%;
}
.visual > img {
  display: block;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
  position: absolute;
  width: 100%;
}

.popupContent {
  color: rgba(255, 255, 255, 0.6);
  line-height: 1.42857;
  letter-spacing: -0.05em;
  padding: 4.166666666666667vw 6.770833333333333vw 0;
}
.date {
  color: white;
  font-weight: 500;
  margin-bottom: 1.041666666666667vw;
}
.popupContent > h1 {
  color: #fff;
  font-size: 2.5vw;
  font-style: normal;
  font-weight: 500;
  line-height: 1.1;
  letter-spacing: -0.05em;
  margin-bottom: 2.083333333333333vw;
}
.wysiwyg > * {
  margin-bottom: 1.041666666666667vw;
}
.wysiwyg > *:last-child {
  margin-bottom: 0;
}
.wysiwyg > h2 {
  color: white;
  font-size: 1.875vw;
  font-weight: 500;
  line-height: 1.1;
  margin: 3.125vw 0 1.666666666666667vw;
}
.wysiwyg > h3 {
  color: white;
  font-size: 1.25vw;
  font-weight: 500;
  line-height: 1.1;
  margin: 2.083333333333333vw 0 1.25vw;
}
.wysiwyg > h4 {
  color: white;
  font-size: 1.041666666666667vw;
  font-weight: 500;
  line-height: 1.1;
  margin: 1.666666666666667vw 0 1.041666666666667vw;
}
.wysiwyg > h5 {
  color: white;
  font-size: 0.9375vw;
  font-weight: 500;
  line-height: 1.1;
  margin: 1.25vw 0 1.041666666666667vw;
}
.wysiwyg > h6 {
  color: white;
  font-size: 0.833333333333333vw;
  font-weight: 500;
  line-height: 1.1;
  margin: 1.041666666666667vw 0 0.833333333333333vw;
}
.wysiwyg > blockquote {
  background-color: rgba(255, 255, 255, 0.1);
  border-left: 2px solid #27a845;
  border-radius: 0 4px 4px 0;
  color: white;
  font-weight: 500;
  margin: 2.083333333333333vw 0;
  padding: 0.833333333333333vw 0 0.833333333333333vw 1.145833333333333vw;
}
.wysiwyg cite {
  font-style: normal;
}
.wysiwyg a {
  color: #298ed7;
  text-decoration: none;
}
.wysiwyg > figure {
  margin: 2.083333333333333vw 0;
}
.wysiwyg > figure > figcaption {
  margin-top: 0.416666666666667vw;
}
.wysiwyg img {
  display: block;
  height: auto;
  max-width: 100%;
}
.wysiwyg > ul {
  margin-bottom: 2.083333333333333vw;
}
.wysiwyg ul > li {
  margin-bottom: 0.833333333333333vw;
  padding-left: 0.9375vw;
  position: relative;
}
.wysiwyg ul > li::before {
  aspect-ratio: 1 / 1;
  background-color: #eba934;
  border-radius: 50%;
  content: "";
  left: 0;
  position: absolute;
  top: 0.364583333333333vw;
  width: 0.3125vw;
}
.wysiwyg ul > li:last-child {
  margin-bottom: 0;
}
.wysiwyg iframe {
  aspect-ratio: 16 / 9;
  display: block;
  height: auto;
  width: 100%;
  max-width: 100%;
}
.wysiwyg > ol {
  counter-reset: ol;
  margin-bottom: 2.083333333333333vw;
}
.wysiwyg ol > li {
  counter-increment: ol;
  margin-bottom: 0.833333333333333vw;
  padding-left: 1.458333333333333vw;
  position: relative;
}
.wysiwyg ol > li::before {
  aspect-ratio: 1 / 1;
  background-color: white;
  content: counter(ol);
  color: #0f1011;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0.104166666666667vw;
  height: 0.833333333333333vw;
}
.wysiwyg ol > li:last-child {
  margin-bottom: 0;
}
.wysiwyg table {
  border-collapse: collapse;
  width: 100%;
}
.wysiwyg table td {
  border: 1px solid rgba(255, 255, 255, 0.2);
  padding: 0.520833333333333vw 0.833333333333333vw;
}
.wysiwyg table > thead td {
  background-color: white;
  border-color: white;
  color: #0f1011;
  font-weight: 500;
}
.wysiwyg video {
  height: auto;
  max-width: 100%;
}

@media (pointer: fine) {
  .close:hover > svg > circle {
    fill: #d8323a;
  }
  .wysiwyg a:hover {
    text-decoration: underline;
  }
}

@media (max-width: 1024px) {
  .overlay {
    display: none;
  }

  .popup {
    width: 100%;
  }

  .close {
    right: 16px;
    top: 16px;
    width: 40px;
  }
  .popupLoading > svg {
    width: 120px;
  }

  .popupContent {
    padding: 40px 16px 0;
  }
  .date {
    margin-bottom: 16px;
  }
  .popupContent > h1 {
    font-size: 36px;
    margin-bottom: 32px;
  }
  .wysiwyg > * {
    margin-bottom: 16px;
  }
  .wysiwyg > h2 {
    font-size: 30px;
    margin: 40px 0 32px;
  }
  .wysiwyg > h3 {
    font-size: 24px;
    margin: 40px 0 24px;
  }
  .wysiwyg > h4 {
    font-size: 20px;
    margin: 24px 0 20px;
  }
  .wysiwyg > h5 {
    font-size: 18px;
    margin: 20px 0 20px;
  }
  .wysiwyg > h6 {
    font-size: 16px;
    margin: 20px 0 16px;
  }
  .wysiwyg > blockquote {
    margin: 40px 0;
    padding: 16px 0 16px 22px;
  }
  .wysiwyg > figure {
    margin: 40px 0;
  }
  .wysiwyg > figure > figcaption {
    font-size: 12px;
    margin-top: 8px;
  }
  .wysiwyg > ul {
    margin-bottom: 40px;
  }
  .wysiwyg ul > li {
    margin-bottom: 16px;
    padding-left: 18px;
  }
  .wysiwyg ul > li::before {
    top: 7px;
    width: 6px;
  }
  .wysiwyg > ol {
    margin-bottom: 40px;
  }
  .wysiwyg ol > li {
    margin-bottom: 16px;
    padding-left: 28px;
  }
  .wysiwyg ol > li::before {
    top: 2px;
    height: 16px;
  }
  .wysiwyg table td {
    padding: 8px 10px;
  }
}

.share {
  margin: 3.125vw 0 1.25vw;
}
.shareDesktop {
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-width: 1px 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 1.25vw 0;
  position: relative;
}
.shareDesktop > nav {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.shareDesktop > nav > button {
  aspect-ratio: 1 / 1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 0.625vw;
  width: 1.25vw;
}
.shareDesktop > nav > button > svg {
  display: block;
  width: 100%;
}
.shareDesktop > nav > button > svg > path:not(.noFill),
.shareDesktop > nav > button > svg > rect {
  fill: white;
  transition: fill 0.25s ease;
}
.shareDesktop > nav > button.copied {
  pointer-events: none;
}
.shareDesktop > nav > button.copied::after {
  content: "Saved";
  position: absolute;
  right: 0;
}

@media (pointer: fine) {
  .shareDevice {
    display: none;
  }
  .shareDesktop > nav > button:hover > svg > path:not(.noFill),
  .shareDesktop > nav > button:hover > svg > rect {
    fill: #27a845;
  }
}

@media (pointer: coarse) {
  .shareDesktop {
    display: none;
  }
  .shareDevice {
    margin: 40px 0;
  }
  .shareDevice > button {
    background-color: white;
    border-radius: 6px;
    color: #0f1011;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: 500;
    height: 46px;
    letter-spacing: -0.05em;
    width: 100%;
  }
}
