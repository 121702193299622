.footer {
  padding: 0.833333333333333vw;
}
.logo > img {
  display: block;
  height: 3.125vw;
  margin: 0 auto;
  width: auto !important;
}
.bottom {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  margin-top: 1.25vw;
}
.copyright,
.developer {
  color: rgba(255, 255, 255, 0.3);
  flex: 0 0 auto;
  font-size: 0.625vw;
  white-space: nowrap;
}
.developer > a {
  color: rgba(255, 255, 255, 0.3);
  text-decoration: none;
  transition: color 0.25s ease;
}
.center {
  flex: 0 1 100%;
  text-align: center;
}
.center > nav {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.78125vw;
}
.center > nav > a {
  aspect-ratio: 1 / 1;
  display: block;
  margin: 0 0.416666666666667vw;
  width: 1.25vw;
}
.center > nav > a > img {
  aspect-ratio: 1 / 1;
  display: block;
  height: auto !important;
  width: 100% !important;
  transition: transform 0.25s ease;
}
.center > a {
  color: white;
  font-weight: 500;
  text-decoration: none;
  transition: color 0.25s ease;
}

@media (pointer: fine) {
  .developer > a:hover {
    color: rgba(255, 255, 255, 1);
  }
  .center > a:hover {
    color: #298ed7;
  }
  .center > nav > a:hover > img {
    transform: scale(1.25);
  }
}

@media (max-width: 1024px) {
  .footer {
    padding: 16px;
  }
  .logo > img {
    height: auto !important;
    width: 220px !important;
    max-width: 100%;
  }
  .bottom {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-top: 24px;
  }
  .copyright,
  .developer {
    font-size: 12px;
  }
  .copyright {
    margin-top: 16px;
    order: 2;
  }
  .center {
    margin-bottom: 40px;
  }
  .center > nav {
    margin-bottom: 16px;
  }
  .center > nav > a {
    margin: 0 8px;
    width: 24px;
  }
}
