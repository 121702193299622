.window {
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;
}
.screenWrapper {
  transform: translate3d(0, 0, 0);
  will-change: transform;
}
.screen {
  /* background-color: #0f1011; */
  padding: calc(var(--Grid-gap) / 2);
  position: relative;
  transform: translate3d(0, 0, 0);
  /* backface-visibility: hidden; */
  will-change: transform;
}
.screen.active {
  z-index: 3;
}
.screen.hiding {
  z-index: 2;
}
.screenCopy {
  left: 0;
  position: absolute;
  top: 0;
}
.grid {
  display: grid;
  column-gap: var(--Grid-gap);
  row-gap: var(--Grid-gap);
}
.section {
  position: relative;
}
.section.active {
  z-index: 3;
}
.section.hiding {
  z-index: 2;
}
