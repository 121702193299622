a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
b,
big,
blockquote,
body,
canvas,
caption,
center,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
output,
p,
pre,
q,
ruby,
s,
samp,
section,
small,
span,
strike,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
tt,
u,
ul,
var,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote::after,
blockquote::before {
  content: "";
  content: none;
}

q::after,
q::before {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

svg {
  display: block;
  overflow: visible;
  position: relative;
}

button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

:root {
  --Grid-column: 12.5vw; /* 240px */
  --Grid-gap: 2px;
}

html,
body {
  background-color: #0f1011;
  backface-visibility: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--black-text);
  font-family: "Spline Sans Mono", monospace;
  font-optical-sizing: auto;
  font-weight: 1.1;
  font-style: normal;
  font-size: 0.729166666666667vw;
  line-height: 1.2;
  margin: 0;
  height: 100%;
  overflow: hidden;
  width: 100%;
}

@media (max-width: 1024px) {
  html,
  body {
    font-size: 14px;
  }
}
