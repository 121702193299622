.loading {
  background-color: white;
  bottom: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 5000;
  transition: opacity 1s ease;
}
.loading.active {
  opacity: 1;
  pointer-events: auto;
}
