.block {
  /* background-color: #000; */
  height: 100%;
  text-decoration: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
}
.content {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  height: 100%;
  padding: 0.833333333333333vw;
  position: relative;
  width: 100%;
}
.bg {
  height: 100%;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100%;
  transition: transform 0.5s ease;
  transform: translate3d(0, 0, 0) scale(1);
  will-change: transform;
}
.bg > img {
  display: block;
  height: 100%;
  object-fit: contain;
  object-position: 50% 50%;
  pointer-events: none;
  width: 100%;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: -moz-none;
}
.bg > img.cover {
  object-fit: cover;
}
.tag {
  background-color: #000;
  border-radius: 0px 0px 4px 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 0.729166666666667vw;
  font-style: normal;
  font-weight: 500;
  line-height: 1.2px;
  letter-spacing: -0.05em;
  height: 1.354166666666667vw;
  left: 50%;
  padding: 0 0.625vw;
  position: absolute;
  top: 0;
  transform: translate3d(-50%, 0, 0);
  text-transform: lowercase;
}
.bottom {
  background-color: #fff;
  box-sizing: border-box;
  color: #000;
  padding: 0.520833333333333vw;
  position: relative;
  text-align: center;
  width: 100%;
}
.bottom > h2 {
  font-size: 1.041666666666667vw;
  font-style: normal;
  font-weight: 500;
  line-height: 1.1;
  letter-spacing: -0.05em;
}
.bottom > h2 + p {
  margin-top: 0.625vw;
}

@media (pointer: fine) {
  a.block:hover .bg,
  button.block:hover .bg {
    transform: translate3d(0, 0, 0) scale(1.075);
  }
}

@media (max-width: 1024px) {
  .content {
    padding: 6px;
  }
  .tag {
    font-size: 12px;
    height: 24px;
    padding: 0 8px;
  }
  .bottom {
    padding: 4px;
  }
  .bottom > h2 {
    font-size: 14px;
  }
  .bottom > p {
    font-size: 12px;
  }
  .bottom > h2 + p {
    margin-top: 8px;
  }
}
