.hamburger {
  aspect-ratio: 1 / 1;
  position: fixed;
  right: 0.833333333333333vw;
  top: 0.833333333333333vw;
  width: 3.125vw;
  z-index: 6200;
}
.hamburger > svg {
  display: block;
  width: 100%;
}
.hamburger > svg > circle,
.hamburger > svg > path:not(.noStroke) {
  stroke: #0f1011;
  transition: stroke 0.25s ease, transform 0.5s ease;
}
.hamburger > svg > path.line1 {
  transform-origin: 45% 46%;
}
.hamburger > svg > path.line2 {
  transform-origin: 45% 54%;
}
.hamburger.open > svg > path.line1 {
  transform: rotate(45deg);
}
.hamburger.open > svg > path.line2 {
  transform: rotate(-45deg);
}

.OverlayOpen {
  animation: openOverlay 0.5s ease-out forwards;
}

.OverlayClosed {
  animation: closeOverlay 0.35s ease-out forwards;
}

.ModalOpen {
  animation: openModal 0.5s ease-out forwards;
}

.ModalClosed {
  animation: closeModal 0.35s ease-out forwards;
}

@keyframes openOverlay {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes closeOverlay {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes openModal {
  0% {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }

  100% {
    opacity: 1;
    transform: translate3d(0%, 0, 0);
  }
}

@keyframes closeModal {
  0% {
    opacity: 1;
    transform: translate3d(0%, 0, 0);
  }

  100% {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }
}

.overlay {
  background: rgba(15, 16, 17, 0.4);
  backdrop-filter: blur(1px);
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 6000;
}

.popup {
  background-color: #0f1011;
  color: white;
  height: 100%;
  position: fixed;
  right: 0;
  top: 0;
  transform: translate3d(0, 0, 0);
  width: 50%;
  z-index: 6100;
}
.popupContainer {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  height: 100%;
  overflow: auto;
  position: relative;
}
.popupContent {
  margin-bottom: 2.083333333333333vw;
  padding: 4.166666666666667vw 6.770833333333333vw 0;
  text-align: center;
}
.popupContent > h2 {
  font-size: 1.875vw;
  font-style: normal;
  font-weight: 500;
  line-height: 1.1;
  margin-bottom: 2.604166666666667vw;
}
.popupContent > nav {
  display: block;
  margin: 0 auto;
  width: 15.625vw;
}
.popupContent > nav > button {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.041666666666667vw;
  font-weight: 500;
  height: 1.875vw;
  margin-bottom: 2px;
  position: relative;
  width: 100%;
  transition: background-color 0.25s ease, color 0.25s ease;
}
.popupContent > nav > button:last-child {
  margin-bottom: 0;
}
.popupContent > nav > button.selected {
  background-color: white;
  color: #0f1011;
}
.popupContent > nav > button:not(.all)::before {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE4IDhMMTAuNjE1NCAxNS4zODQ2TDYgMTAuNzY5MiIgc3Ryb2tlPSIjMEYxMDExIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJzcXVhcmUiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==");
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
  aspect-ratio: 1/1;
  content: "";
  left: 0.833333333333333vw;
  opacity: 0;
  position: absolute;
  width: 1.25vw;
  transition: opacity 0.25s ease;
}
.popupContent > nav > button:not(.all).selected::before {
  opacity: 1;
}
.popupContent > nav > button.all.selected {
  pointer-events: none;
}

@media (pointer: fine) {
  .hamburger:hover > svg > circle,
  .hamburger:hover > svg > path:not(.noStroke) {
    stroke: #298ed7;
  }
  .popupContent > nav > button:hover {
    background-color: #eba934;
  }
}

@media (max-width: 1024px) {
  .overlay {
    display: none;
  }

  .popup {
    width: 100%;
  }

  .hamburger {
    right: 16px;
    top: 16px;
    width: 40px;
  }
  .popupContent {
    margin-bottom: 24px;
    padding: 80px 16px 0;
  }
  .popupContent > h2 {
    font-size: 36px;
    margin-bottom: 36px;
  }
  .popupContent > nav {
    width: 300px;
  }
  .popupContent > nav > button {
    font-size: 18px;
    height: 36px;
  }
  .popupContent > nav > button:not(.all)::before {
    left: 16px;
    width: 24px;
  }
}
